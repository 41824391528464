body {
    margin: 0;
    font-family: Arial, sans-serif;
    background: url('/public/images/tatreez.png') no-repeat center center fixed;
    background-size: cover;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 56, 38, 0.8); /* Green overlay */
    z-index: -1; /* Place it behind the content */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: hue-rotate(90deg); /* Apply green hue */
    z-index: -2; /* Place it behind the green overlay */
  }
  
  .results-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
  }
  
  .logo {
    width: 150px;
  }
  
  .results-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    border-radius: 10px;
    max-width: 500px;
    z-index: 1;
  }
  
  .results-content h1 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .result {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .next-button {
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    border: 2px solid white; /* White border */
    border-radius: 10px;
    color: #fff;
    background-color: #8B0000; /* Blood red color */
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .next-button:hover {
    background-color: #600000; /* Darker red for hover effect */
    transform: scale(1.1); /* Popping effect */
  }
  
  .home-button {
    padding: 10px 20px; /* Make button smaller */
    font-size: 1.2em; /* Adjust font size */
    cursor: pointer;
    border: 2px solid white; /* White border */
    border-radius: 10px;
    color: #fff;
    background-color: #8B0000; /* Blood red color */
    transition: transform 0.3s, background-color 0.3s;
    align-self: center; /* Center button at the bottom */
    position: absolute;
    bottom: 20px;
  }
  
  .home-button:hover {
    background-color: #600000; /* Darker red for hover effect */
    transform: scale(1.1); /* Popping effect */
  }
  