body {
    margin: 0;
    font-family: Arial, sans-serif;
    background: url('/public/images/tatreez.png') no-repeat center center fixed;
    background-size: cover;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 56, 38, 0.8); /* Green overlay */
    z-index: -1; /* Place it behind the content */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: hue-rotate(90deg); /* Apply green hue */
    z-index: -2; /* Place it behind the green overlay */
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    z-index: 1; /* Ensure it's above the overlay */
  }
  
  .logo {
    width: 200px;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 1.5em;
    font-weight: bold; /* Make the text bold */
    margin: 0;
  }
  
  .homepage {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    border-radius: 10px;
    max-width: 500px; /* Set a max width for the green background */
    z-index: 1; /* Ensure it's above the overlay */
  }
  
  .content {
    text-align: center;
    margin-top: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    font-weight: bold; /* Make the text bold */
    margin-bottom: 20px;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .baddie-button, .seeker-button {
    padding: 20px 40px; /* Increase button size */
    margin: 10px;
    font-size: 1.8em; /* Increase font size */
    cursor: pointer;
    border: 2px solid white; /* White border */
    border-radius: 10px;
    color: #fff;
    background-color: #8B0000; /* Blood red color */
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .baddie-button:hover, .seeker-button:hover {
    background-color: #600000; /* Darker red for hover effect */
    transform: scale(1.1); /* Popping effect */
  }
  